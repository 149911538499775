.chatBody::-webkit-scrollbar {
  width: 0.2em;
}

.chatBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.chatBody::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.chatBody {
  padding: 10px 0px 20px 27px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  overflow-x: hidden;

  .box {
    display: block;
    flex: auto;
    &.chatbotImg {
      min-width: 55px;
      max-width: 55px;
      justify-content: center;
    }
  }

  .botMessage {
    max-width: 400px;
    display: flex;
    flex-direction: row;
    padding-right: 40px;
  }

  .botCard {
    max-width: 220px;
    height: auto;
    left: 159px;
    top: 273px;
    background: var(--card-background-color);
    border-radius: 10px 10px 10px 0px;
    padding: 10px 15px 10px 12px;
    overflow-wrap: break-word;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight:600;
    color: var(--card-text-color);
    line-height: 20px;
  }

  .userMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Align to the right */
    margin-bottom: 5px;
  }

  .userMess {
    max-width: 220px;
    margin-bottom: 10px;
    opacity: 1;
    margin-right: 10px;
    border: 55px solid #000;
    border-radius: 16px !important;
    border: #000 !important;
    filter: drop-shadow(0px 0px 1px #dae4fc);
    padding: 6px;
    font-size: 14px;
  }

  .userMess::before {
    content: "";
    position: absolute;
    background: rgb(194, 185, 185);
    transform: scale(var(--x, 1), var(--y, 1));
  }

  .user-item-matcard.p-right::before {
    left: 100%;
    clip-path: polygon(0% 0, 0% 100%, 100% 50%);
  }

  .userCard {
    font-weight: 500;
    border-radius: 10px 0px 10px 10px;
    inline-size: auto;
    padding: 15px;
    // background: rgba(0, 0, 0, 0.05);
    border: 1px solid var(--userCard-border-color);
    overflow-wrap: break-word;
  }

  .chatLogo {
    display: block;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    // background-color: #000;
    // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.chatFooter {
  width: 344px;
  padding: 10px 25px;
  position: relative;
}

.MuiOutlinedInput-root {
  &::fieldset {
    border: "none";
  }
}

.textfield {
  // width: "auto";
  background-color: "#F5F5F5";
  border-radius: 10px;
  height: 58px;
  background: rgba(0, 0, 0, 0.05);
  // width:18rem;
  &::placeholder {
    font-size: 16px;
    font-weight: 400px;
    font-family: "Montserrat";
    font-style: "normal";
    color: rgba(0, 0, 0, 0.3);
  }
}

form {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
  width: 100%;
}

.icon {
  position: absolute;
  padding: 10px;
  top: 16px;
  right: 30px;
  cursor: pointer;
}

.emoji {
  width: 20px;
  padding-left: 15px;
}

.attachment {
  width: 16px;
  padding-left: 15px;
}

.formikk {
  // width: 100%; /* Full width by default */
  max-width: 768px; /* Set the maximum width to limit the container on larger screens */
  // margin: 0 auto; /* Center the container horizontally */
  padding: 11px 20px !important;
  // background-color: red !important;
}

.microphone {
  width: 16px;
  padding-left: 15px;
}

.sendbtn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  // width: 55px;
  height: 55px;
}

.sendbtnImg {
  width: 55px;
  height: 55px;
}

.footertext {
  display: flex;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  margin: 3px 22px 6px;
  justify-content: flex-end;
}

.oxylym {
  padding-left: 3px;
  font-weight: 600;
  font-size: 13px;
  color: #6e7a71;
}

.chipsContainerCard {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  // margin: -4px !important;
  word-wrap: wrap !important;
}

.cardInfoContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.wrapper {
  margin: 10px auto !important;
  max-width: 20rem;
  display: inline-flex;
}

.cardOuterContainer {
  max-width: 18rem !important;
  padding: 0 !important;
  overflow: hidden !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  height: 350px;
  .cardInnerContainer {
    width: auto !important;
    white-space: nowrap !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch !important;
    height: 370px;
  }
}

.careerFlowValMain {
  background-color: #f87700;
}

.chips {
  background: white;
  // border: 1px solid #aec7be !important;
  border-radius: 7px !important;
  margin-right: 10px !important;
  margin-bottom: 15px !important;
  font-size: 12px !important;
  line-height: 15px !important;
  max-width: 270px !important;
  min-height: 30px !important;
  height: auto !important;
  font-family: "Mulish", sans-serif !important;
  transition: background 0.3s, border-color 0.3s, color 0.3s; /* Add transition for smooth effect */
}

/* Hover styles when the Chip component is hovered */
.chips:hover {
  background: var(--chip-hover-background-color) !important; /* Change the background color on hover */
  border-color: var(--chip-hover-border-color);
  cursor: pointer;
  color: var(--chip-hover-text-color) !important; /* Change the font color on hover */
}

.infoTextMain {
  // width:265px !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  // border: 2px solid #bcc8c4 !important;
}

.infoTextTitle {
  font-size: 13px !important;
  font-weight: 600 !important;
  // color:#333333;
  color: var(--infoTextTitle-text-color);
}

.meetingCardTitle {
  font-size: 0.9rem !important;
  // margin: 10px 0 0 1rem !important;
  font-family: Montserrat, sans-serif !important;
  margin-bottom: 0 !important;
  color: var(--meetingCradTitle-text-color);
}

.infoTextSubtitle {
  font-size: 0.8rem !important;
  font-family: Montserrat, sans-serif !important;
}

.cardService {
  flex: 0 0 auto;
  margin-right: 15px; /* Optional: Adjust the spacing between cards */
  width: 195px;
  background: #ffffff !important;
  // box-shadow: 5px 3px 12px #d9d9d9  !important;
  border-radius: 5px;
  border: 2px solid rgb(208 219 214) !important;
  white-space: pre-wrap !important;
  max-height: 300px !important;
  padding: 10px !important;
  height: 300px; // for setting fixed height for the card
  position: relative; // for keeping the chip below the service card fixed
  cursor: pointer;
  box-shadow: none !important;
}

.cardInfoSubtitle {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 8.1rem;
  font-size: 0.8rem !important;
}

.sliderightContainer,
.slideleftContainer {
  border: none !important;
  background: none !important;
  cursor: pointer !important;
  height: 1rem !important;
  margin-top: 9rem;
  filter: drop-shadow(4px 3px 4px rgba(0, 0, 0, 0.2));
}

.arrowImg {
  height: 20px;
}

.slideleftContainer {
  transform: scaleX(-1);
}

.cardInfoChips {
  background: var(--cardInfoChips-background-color);
  border: 1px solid var(--cardInfoChips-border-color) !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
  margin-bottom: 15px !important;
  font-size: 12px !important;
  line-height: 15px !important;
  max-width: 213px !important;
  min-height: 30px !important;
  height: auto !important;
  // justify-content: space-around !important;
}

.chipstyle {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.jobDetailsFormMain {
  background-color: var(--jobDetailsFormMain-background-color);
  padding: 15px 15px;
  border-radius: 10px;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  width: 15.5rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.jobDetailsForm {
  flex-direction: column !important;
  align-items: left !important;
  width: 100%;
}

.formTextArea {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 240px;
  border-radius: 5px;
  height: 2.5rem;
  border-color: var(--formTextArea-border-color);
  background: var(--formTextArea-background-color);
  border: 0.5px solid var(--formTextArea-border2-color);
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 14px;
  font-weight: 500;
  padding-left: 9px;

  &:focus,
  &:active {
    // Styles when the input is focused or active
    border: 0.5px solid var(--formTextArea-active-border-color);
    background-color: var(--formTextArea-active-background-color);
  }
}

.formSubmitBtn {
  background-color: var(--formSubmitBtn-background-color) !important;
  align-self: center;
  border-radius: 7px !important;
  text-transform: none !important;
  font-family: Montserrat, sans-serif !important;
  height: 2rem;
}

.labelClass {
  font-family: Montserrat, sans-serif !important;
  margin-bottom: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.jobDetailsFormMain input[name="jobTitle"],
.jobDetailsFormMain input[name="experience"],
.jobDetailsFormMain input[name="skillsRequired"] {
  /* Your styles here */
  font-family: Montserrat, sans-serif;
}

.uploadResumeMain {
  width: 95%;
  display: flex;
  flex-direction: column; /* Display the content in a column */
  align-items: flex-start; /* Center the elements horizontally */
}

.uploadBtns {
  background-color: var(--uploadBtns-background-color) !important;
  text-transform: none !important;
  color: var(--uploadBtns-text-color) !important;
  width: 110px; /* Set a fixed width for both buttons */
  height: 36px; /* Set a fixed height to ensure both buttons have the same height */
  margin: 5px; /* Add some margin to separate the buttons */
  border-radius: 7px !important;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.selctedFile,
.errorMessage {
  width: 100%; /* Add a width to the selectedFile and errorMessage containers */
  display: flex; /* Add display flex to properly position the elements inside */
  align-items: center; /* Center the elements vertically */
  margin-bottom: 5px; /* Add some spacing between the file/error message and the buttons */
  padding: 0 10px; /* Add padding to the containers for better visual appearance */
  // justify-content: center;
}

.errorMessage {
  color: red;
}

.selctedFile .closeIcon {
  margin-left: 10px;
  cursor: pointer;
}

.css-qjxa9m-MuiPaper-root-MuiCard-root {
  width: calc(100% - 65px);
}

@media screen and (max-width: 480px) {
  .chatBody {
    // max-height: 100vh;
    // height: calc(87vh - 24vh);

    .chatLogo {
      width: 40px !important;
    }

    .jobDetailsFormMain {
      width: 14rem;
    }

    .formTextArea {
      width: 95%;
      max-width: 100%;
      height: 2.1rem;
      font-size: 13px;
    }

    .labelClass {
      font-size: 13px;
    }

    .infoTextMain {
      width: 16rem !important;
    }

    .infoTextTitle {
      font-size: 0.8rem !important;
    }

    .chatbotImg {
      min-width: 50px !important;
      max-width: 50px !important;
    }

    .cardOuterContainer {
      max-width: 16rem !important;
    }

    .formikk {
      width: 90%;
    }
  }
}

.fadeIIn {
  opacity: 0;
  animation: fadeIn ease-in 1 !important; //function name, which animation, number of times to repeat
  animation-fill-mode: forwards !important;
  animation-duration: 0.5s !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slideInRright {
  animation: slideInRight ease-in 0.4s !important;
  animation-fill-mode: forwards !important;
  opacity: 0;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Selected chip styles */
.chips.selected {
  background: var(--chip-selected-background-color) !important;
  border: 1px solid var(--chip-selected-border-color) !important;
  color: var(--chip-selected-color) !important; /* Change text color to white for selected chips */
}

/* Hover styles for chips, including selected chips */
.chips.selected:hover {
  background: var(--chip-selected-hover-color) !important;
  border-color: var(--chip-selected-hover-border-color);
  color: var(--chip-selected-hover-text-color) !important; /* Change font color to white on hover for selected chips */
  cursor: pointer;
}

.noHoverOverlay:hover {
  background-color: transparent;
}

.MuiCardActionArea-root:hover {
  background-color: none !important;
}

.jobImg {
  height: 90px;
  background-color: aliceblue;
}

.jobContent {
  padding: 5px !important;
}

.errorText {
  color: red; /* Customize the color as needed */
  font-size: 12px; /* Customize the font size as needed */
  margin-bottom: 10px; /* Add margin for spacing */
}

.enabledButton {
  background-color: var(--enabledBtn-background-color) !important;
  align-self: center;
  border-radius: 7px !important; /* White text color for enabled button */
}

/* Disabled Button Style */
.disabledButton {
  background-color: white !important; /* Gray background color for disabled button */
  border: 2px solid var(--disabled-border-color) !important;
  color: var(--disabled-text-color) !important; /* Darker gray text color for disabled button */
  cursor: not-allowed; /* Change cursor to not-allowed for disabled button */
}

.accordionContainer {
  border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f6ff;
    overflow: hidden;
    padding: 10px;
    margin-bottom: 15px;
}

.accordionHeader {
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 500;
  color: var(--accordion-header-text-color, #333);
  background-color: var(--accordion-header-bg, #f5f5f5);
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordionHeader:hover {
  background-color: var(--accordion-header-hover-bg, #eaeaea);
}

.accordionContent {
  padding: 10px 15px;
  background-color: var(--accordion-content-bg, #f9f9f9);
  font-size: 14px;
  color: var(--accordion-content-text-color, #666);
  display: none;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordionOpen .accordionContent {
  display: block;
  max-height: 200px; /* Adjust based on content */
}

.accordionOpen .accordionHeader {
  background-color: var(--accordion-open-header-bg, #e0e0e0);
  font-weight: 600;
}

.accordionIcon {
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.accordionOpen .accordionIcon {
  transform: rotate(180deg);
}

.accordion, .accordion-header, .accordion-body {
  font-family: 'Montserrat', sans-serif !important;
}


@media screen and (max-width: 480px) {
  .accordionContainer {
    width: 90%;
    font-family: Montserrat, sans-serif !important
  }

  .accordionHeader {
    font-size: 14px;
    padding: 10px 12px;
  }

  .accordionContent {
    font-size: 13px;
  }
}



