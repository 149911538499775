.chatheaderMain {
  background-color: white;
  padding:15px 15px 0px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: row;
  .box {
    display: flex;
    flex: auto;
    &.chatheaderImg {
      min-width: 40px;
      max-width: 55px;
      justify-content: center;
    }
    &.chatheaderText {
      position: relative;
      .chatMainText {
         display: flex ;
         flex-direction: column ;
         position: absolute ;
         inset: 0 ;
         justify-content: center;
         padding: 8px ;
        .name {
         white-space: nowrap ;
         overflow: hidden ;
         width: 100% ;
         max-width: 100% ;
         text-overflow: ellipsis ;
         font-weight: bold ;
         font-size: 24px ;
        }
      }
    }
    &.chatClose {
      min-width: 30px;
      max-width: 30px;
      justify-content: center;
      align-items: center;
      // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
      // filter: drop-shadow(3px 4px 2px rgb(0 0 0 / 0.6));
      .customIconButton {
        /* Custom styles for the IconButton */
        min-width: 30px;
        max-width: 30px;
        justify-content: center;
        align-items: center;
        // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6));
      }
    }
  
  .chatheaderLogo {
    // display: block;
    // width: 62px;
    // height: 62px;

    // border-radius: 50%;


    // border-radius: 50%;

    // background: var(--header-logo-color);
    // // box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
    // width: 50px;
    // height: 50px;
    // object-fit: contain;
    // border:1px solid #c21818a1;


    // border: 2px solid #ddd;


    // padding: 3px;
  }

  .status{
    color:#088761;
  }
  }
}

/* Media query for screens with a maximum width of 480px (mobiles) */
@media screen and (max-width: 480px) {
 
  .chatheaderLogo {
    width: 50px !important;
    height: 50px !important;
  }

  .chatheaderText{
    .name{
    font-size: 22px !important;
    }
  }
}
