body {
  font-family: Montserrat, sans-serif !important;
  /* background-color: black; */
}

.btnImagesClosed {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
}

.close1{
display: none;
}

.open1{
  display: block;
}

.chatOpenBtnBg {
  width: 45px;
  border-radius: 50%;
}

.chatOpenBtnIcon, .chatCloseBtnIcon{
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  width: 50px;
  bottom: 0;
  right: 0;
}

/* infotext box */
.css-186lkq-MuiPaper-root-MuiCard-root {
  box-shadow: 3px 3px 1px -1px rgba(0, 0, 0, 0.2), /* Top shadow */
  3px -3px 1px -1px rgba(0, 0, 0, 0.2), /* Bottom shadow */
  -3px 3px 1px -1px rgba(0, 0, 0, 0.2), /* Right shadow */
  -3px -3px 1px -1px rgba(0, 0, 0, 0.2) !important; /* Left shadow */
}


.jobDetailsFormMain label {
  font-family: 'Montserrat', sans-serif !important;
}
.reponseRenderer{
  margin-bottom: 10px;
  word-break: break-word !important;
}
.reponseRenderer a{
  color: #000;
  padding-bottom: 3px;
  /* border-bottom: 1px solid rgb(10, 187, 122); */
  background: var(--infotext-link-background-color);
  border-radius: 3px;
  padding: 4px 4px 4px 0px;
  text-decoration: none;
  }