.chatbotMain {
  background-color: white;
  position: fixed;
  bottom: 1vh;
  right: 0px;
  border-radius: 12px !important;
  max-width: 420px;
  min-width: 255px;
  height: 90vh;
  width: 99vw;
  display: flex;
  flex-direction: column;
  border: 2px solid #ebebeb;  
}

.chatbotMain .dividerCustom {
  border: 1px solid rgb(109 107 107 / 20%);
  margin: 12px 20px;
}
